<template>
  <v-container class="py-5">
    <!-- Address Dialog -->
    <v-dialog v-model="showAddressDialog" max-width="800">
      <v-card>
        <v-card-title>Change Address</v-card-title>
        <v-card-text>
          <v-row no-gutter>
            <template v-if="useCanadaPost">
              <!-- country selector -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="address.CountryId"
                  :label="$t('components.address_expansion_panel.country')"
                  :items="countries"
                  :rules="[$rules.required]"
                  item-text="Name"
                  item-value="Id"
                  class="required"
                  outlined
                  clearable
                />
              </v-col>

              <!-- canada post address auto complete -->
              <template v-if="address.CountryId">
                <v-col cols="12" md="12" class="py-0 text--secondary">
                  <v-autocomplete
                    :label="
                      $t(
                        'components.address_expansion_panel.start_typing_your_address'
                      )
                    "
                    v-model="selectedAddress"
                    return-object
                    :items="searchResults"
                    :loading="isAddressSearchLoading"
                    :search-input.sync="searchValue"
                    item-text="Description"
                    item-value="Id"
                    clearable
                    append-outer-icon="mdi-magnify"
                    ref="addressList"
                  ></v-autocomplete>
                  <small class="note-text">{{
                    $t("components.address_expansion_panel.address_note")
                  }}</small
                  ><small class="note-text"
                    >{{ " " }}
                    <strong>{{
                      $t(
                        "components.address_expansion_panel.address_note.example"
                      )
                    }}</strong>
                  </small>
                </v-col>
                <!-- use manual address -->
                <v-col cols="12" align-self="center" class="text-center">
                  <span class="black--text">{{
                    $t("components.address_expansion_panel.use_form")
                  }}</span>
                  <v-btn
                    @click="useCanadaPost = false"
                    text
                    color="primary"
                    class="link mb-1"
                  >
                    {{ $t("globals.here") }}
                  </v-btn>
                </v-col>
              </template>
            </template>

            <!-- no canada post -->
            <template v-if="!useCanadaPost">
              <v-row no-gutters align="center" class="py-2">
                <v-icon color="primary">mdi-pencil-outline</v-icon>
                <span class="ml-1 secondary--text">
                  {{ $t("components.address_expansion_panel.enter_address") }}
                </span>
              </v-row>

              <v-form v-model="valid.address">
                <v-row class="mt-1">
                  <v-col cols="12" md="12" class="py-0">
                    <v-select
                      outlined
                      :label="$t('components.address_expansion_panel.country')"
                      :items="countries"
                      v-model="address.CountryId"
                      item-text="Name"
                      item-value="Id"
                      :rules="[$rules.required]"
                      class="required"
                      return-object
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" class="py-0">
                    <no-autocomplete-textfield
                      clearable
                      outlined
                      :label="
                        $t('components.address_expansion_panel.apt_unit_suite')
                      "
                      v-model="address.SuiteNumber"
                    ></no-autocomplete-textfield>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" class="py-0">
                    <no-autocomplete-textfield
                      clearable
                      outlined
                      :label="
                        $t('components.address_expansion_panel.street_number')
                      "
                      v-model="address.StreetNumber"
                      :rules="[$rules.required, $rules.streetNumber]"
                      class="required"
                    ></no-autocomplete-textfield>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <no-autocomplete-textfield
                      clearable
                      outlined
                      :label="
                        $t('components.address_expansion_panel.street_name')
                      "
                      v-model="address.StreetName"
                      :rules="[$rules.required]"
                      class="required"
                    ></no-autocomplete-textfield>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <no-autocomplete-textfield
                      clearable
                      outlined
                      :label="$t('components.address_expansion_panel.city')"
                      v-model="address.City"
                      :rules="[$rules.required]"
                      class="required"
                    ></no-autocomplete-textfield>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-select
                      outlined
                      clearable
                      :label="$t('components.address_expansion_panel.province')"
                      v-model="address.ProvinceStateId"
                      :items="provinceStates"
                      item-text="Name"
                      item-value="Id"
                      :rules="[$rules.required]"
                      class="required"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <no-autocomplete-textfield
                      clearable
                      outlined
                      placeholder="A1A 1A1"
                      v-mask="`A#A #A#`"
                      :label="
                        $t('components.address_expansion_panel.postal_code')
                      "
                      v-model="address.PostalCode"
                      :rules="[$rules.required, $rules.postalCode]"
                      class="required"
                    ></no-autocomplete-textfield>
                  </v-col>

                  <v-col cols="12" align-self="center" class="text-center py-0">
                    <span class="black--text">{{
                      $t("components.address_expansion_panel.lookup_address")
                    }}</span>
                    <v-btn
                      @click="useCanadaPost = true"
                      text
                      color="primary"
                      class="link mb-1"
                    >
                      {{ $t("globals.here") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row justify="end" class="p-3">
                  <v-col align-self="end">
                    <v-btn
                      color="primary"
                      @click.prevent="updateAddress"
                      :disabled="!valid.address"
                    >
                      {{ $t("globals.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Phone dialog -->
    <v-dialog v-model="showPhoneDialog" max-width="300">
      <v-card>
        <v-card-title>Update Phone Number</v-card-title>
        <v-card-text>
          <v-form v-model="valid.contactInfo" ref="form">
            <v-row>
              <v-col cols="12" class="pb-0">
                <no-autocomplete-textfield
                  clearable
                  outlined
                  :label="
                    $t(
                      'components.contact_information_expansion_panel.telephone'
                    )
                  "
                  v-model="phone"
                  :rules="[$rules.required, rules.phone]"
                  class="required"
                  v-mask="telephoneMask"
                />
              </v-col>
            </v-row>
            <v-btn color="primary" @click.prevent="updatePhone">
              {{ $t("globals.save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Info Display -->
    <v-row>
      <!-- Address info -->
      <v-col>
        <v-row justify="center">
          <!-- this is the title ok that is fine but then whatever we do -->
          <v-col cols="12" class="secondary--text pb-2">
            <span>
              <v-icon large color="primary">contact_mail</v-icon>
              <span class="ml-3 text-h6">
                {{ $t("components.address_expansion_panel.address") }}
              </span>
            </span>
          </v-col>

          <!-- displayed address -->
          <v-col cols="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="12" class="py-0 text--secondary">
                <span class="address"> {{ fetchedAddress }} </span>
              </v-col>
            </v-row>
          </v-col>

          <!-- address edit button -->
          <v-col cols="12" md="6" align-self="center">
            <!-- address edit button -->
            <v-btn color="primary" @click="onAddressEdit()">
              <span>
                {{
                  fetchedAddress === undefined
                    ? $t("globals.add")
                    : $t("globals.edit")
                }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- telephone info -->
      <v-col>
        <v-row justify="center">
          <v-col cols="12" class="secondary--text pb-2" align-self="center">
            <span>
              <v-icon large color="primary">phone</v-icon>
              <span class="text-h6 ml-1">
                {{
                  $t("components.contact_information_expansion_panel.telephone")
                }}
              </span>
            </span>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="12" class="py-0 text--secondary">
                <span class="text-subtitle-1">
                  {{ formatPhone(phone) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pb-0"
            v-if="!(addingAddress || addedAddress)"
          >
            <v-btn
              color="primary"
              align-self="end"
              @click="showPhoneDialog = true"
            >
              {{ $t("globals.edit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
// import SignatureInput from "@/components/VueMaterialDashboard/SignatureInput.vue";
import { tokens, mask } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
import states from "@/statesUSA";
import SettingsService from "@/services/settings.service";
import ApiService from "@/services/api.service";

export default {
  components: { NoAutocompleteTextfield },
  props: {
    member: Object,
    savingContactsSba: Boolean,
    memberSaved: Boolean,
  },
  directives: { mask },
  data() {
    return {
      internalMember: {},
      countries: [],
      showAddressDialog: false,
      showPhoneDialog: false,
      provinceStates: [],
      valid: {
        contactInfo: false,
        address: false,
      },
      useCanadaPost: true,
      phone: "",
      address: {
        StreetNumber: "",
        SuiteNumber: "",
        StreetName: "",
        PostalCode: "",
        City: "",
        Country: "",
        CountryId: "",
        ProvinceState: "",
        ProvinceStateId: "",
        PropagateUpdate: false,
      },
      editMode: false,
      selectedCountry: undefined,
      states: states,
      category: null,
      searchValue: undefined,
      searchResults: [],
      selectedAddress: undefined,
      isAddressSearchLoading: false,
      isAddressRetrieveLoading: false,
      fetchedAddress: undefined,
      canadaPostAddress: undefined,
      addingAddress: false,
      addedAddress: false,
      signature: null,
      telephoneMask: "(###) ### - ####",
      rules: {
        phone: (v) => {
          let val = this.unmask(v, this.telephoneMask);
          return !val || this.$rules.phone(val);
        },
      },
      otherCountry: null,
    };
  },
  computed: {
    ...mapGetters("common", ["enumModels", "identityUser", "settings"]),
    hasMultiplePlans() {
      return this.member?.MemberPlans?.length > 1;
    },
    phoneTypes() {
      const en = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Home",
          value: "Home",
        },
      ];
      const fr = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Téléphone",
          value: "Home",
        },
      ];
      return this.$vuetify.lang.current === "fr" ? fr : en;
    },
  },
  watch: {
    member: {
      handler(v) {
        this.internalMember = v;
        this.address = v.Address;
        this.phone = v.Phone;

        console.log("updating member", this.address);
        this.fetchedAddress = [
          v.Address.SuiteNumber,
          v.Address.StreetNumber,
          v.Address.StreetName,
          v.Address.City,
          v.Address.ProvinceState?.Name,
          v.Address.Country?.Name,
          v.Address.PostalCode,
        ].join(" ");
      },
      deep: true,
      immediate: true,
    },
    memberSaved: {
      handler() {
        this.addedAddress = false;
        this.addingAddress = false;
        this.editMode = false;
      },
      immediate: true,
    },
    "valid.contactInfo": {
      handler() {
        // console.log(v);
        this.$emit(
          "update-valid",
          this.valid.contactInfo && this.valid.address
        );
      },
    },
    "valid.address": {
      handler() {
        this.$emit(
          "update-valid",
          this.valid.contactInfo && this.valid.address
        );
      },
    },
    searchValue: {
      async handler(v) {
        if (v === null) {
          return;
        }

        if (this.isAddressSearchLoading) {
          return;
        }
        this.isAddressSearchLoading = true;
        try {
          this.searchResults = (
            await this.canadaPostFind({
              payload: {
                Key: this.settings.CanadaPostKey,
                SearchTerm: this.searchValue,
                Country: this.address.Country,
                LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
              },
            })
          ).data.Items.map((o) => {
            o.Description = `${o.Text} ${o.Description}`;
            return o;
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isAddressSearchLoading = false;
        }
      },
    },
    selectedAddress: {
      async handler(v) {
        if (!v) {
          return;
        }

        if (this.isAddressRetrieveLoading) {
          return;
        }

        if (this.selectedAddress.Next === "Find") {
          this.isAddressSearchLoading = true;

          try {
            let payload = {
              Key: this.settings.CanadaPostKey,
              SearchTerm: `${this.selectedAddress.Description}`,
              Country: this.address.Country,
              LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
            };

            if (this.selectedAddress.Id) {
              payload.LastId = this.selectedAddress.Id;
            }

            this.searchResults = (
              await this.canadaPostFind({
                payload: payload,
              })
            ).data.Items.map((o) => {
              o.Description = `${o.Text} ${o.Description}`;
              return o;
            });
            this.searchValue = null;
            this.onFocus();
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressSearchLoading = false;
          }
        } else if (this.selectedAddress.Next === "Retrieve") {
          this.isAddressRetrieveLoading = true;

          try {
            const results = await this.canadaPostRetrieve({
              payload: {
                Key: this.settings.CanadaPostKey,
                Id: this.selectedAddress.Id,
                LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
              },
            });

            this.canadaPostAddress = results.data.Items;

            const addr = results.data.Items[0]; // we take the first

            this.address.City = addr.City;
            this.address.Country = addr.CountryName;
            this.address.CountryId = this.countries.find(
              (x) =>
                x.Name == addr.CountryName ||
                x.Name == addr.CountryIso2 ||
                x.Name == addr.CountryIso3
            )?.Id;
            this.address.PostalCode = addr.PostalCode;
            this.address.ProvinceState = addr.ProvinceName;
            this.address.ProvinceStateId = this.provinceStates.find(
              (x) =>
                x.Name == addr.ProvinceName ||
                x.Name == addr.ProvinceCode ||
                x.Name == addr.Province
            )?.Id;

            this.address.StreetName = addr.Street;
            this.address.StreetNumber = addr.BuildingNumber;
            this.address.SuiteNumber = addr.SubBuilding;

            this.fetchedAddress = this.canadaPostAddress[0].Label;
            this.addingAddress = false;
            this.addedAddress = true;
            this.useCanadaPost = false;
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressRetrieveLoading = false;
          }
        }
      },
    },
    "address.CountryId": {
      handler(v) {
        this.address.Country = this.countries.find((x) => x.Id == v)?.Name;
        console.log("updating address country", this.address.Country);
      },
    },
    selectedCountry: {
      handler(v) {
        this.internalMember = JSON.parse(JSON.stringify(this.member));
        if (v?.textEn?.toUpperCase() === "CANADA") {
          this.category = "Civic";
          this.internalMember.Address.Country = "Canada";
          this.internalMember.Address.OtherCountry = null;
          this.internalMember.Address.OtherProvince = null;
          this.internalMember.Address.OtherPostalCode = null;
          this.internalMember.Address.AddressLine = null;
          this.internalMember.Address.Province =
            this.internalMember.Address.Province?.toUpperCase();
        }
        if (v?.textEn?.toUpperCase() === "UNITED STATES") {
          this.internalMember.Address.OtherCountry = "United States";
          this.otherCountry = "United States";
          this.category = "Non-Civic US";
          this.internalMember.Address.Country = null;
          this.internalMember.Address.Province = null;
          this.internalMember.Address.PostalCode = null;
        }
        if (v?.textEn?.toUpperCase() === "OTHER") {
          if (this.internalMember.Address.OtherCountry === "United States") {
            this.internalMember.Address.OtherCountry = null;
          }
          this.category = "Non-Civic International";
          this.internalMember.Address.Country = null;
          this.internalMember.Address.Province = null;
          this.internalMember.Address.PostalCode = null;
        }
        this.searchResults = [];
      },
    },
  },
  mounted() {
    console.log("member", this.member);
    var dispatchUrl = SettingsService.settings.DISPATCHURL;

    ApiService.get(dispatchUrl + "countries").then((res) => {
      this.countries = res.data;
    });

    ApiService.get(dispatchUrl + "province-states").then((res) => {
      this.provinceStates = res.data;
    });

    this.internalMember = JSON.parse(JSON.stringify(this.member));

    if (this.internalMember.Address?.ProvinceState) {
      this.internalMember.Address.ProvinceState.Name =
        this.internalMember.Address.ProvinceState.Name.toUpperCase();
    }
  },
  methods: {
    ...mapActions("data", [
      "canadaPostFind",
      "canadaPostRetrieve",
      "updateContactInfo",
    ]),
    async updateAddress() {
      var dispatchUrl = SettingsService.settings.DISPATCHURL;
      try {
        await ApiService.put(dispatchUrl + "update-address", this.address);
      } catch (err) {
        //
      }

      this.useCanadaPost = true;
      this.showAddressDialog = false;
    },

    async updatePhone() {
      var dispatchUrl = SettingsService.settings.DISPATCHURL;
      try {
        await ApiService.put(dispatchUrl + "update-phone", {
          phone: this.phone,
        });
      } catch (err) {
        //
      }

      // close phone dialog
      this.showPhoneDialog = false;
    },

    onAddressEdit() {
      this.showAddressDialog = true;
      // this.addingAddress = true;
      // this.useCanadaPost = true;
    },

    unmask(v, mask) {
      if (v) {
        return masker(v, mask, false, tokens);
      }
      return v;
    },
    async save() {
      if (this.canadaPostAddress && this.useCanadaPost) {
        this.fillMemberAddress();
      } else this.addDataToMemberAddress();
      this.editMode = false;
      this.internalMember.EffectiveDate = this.formattedDateNow();
      let phone;
      if (
        this.internalMember.HomePhone !== null &&
        this.internalMember.HomePhone !== undefined
      ) {
        phone = this.unmask(this.internalMember.HomePhone, this.telephoneMask);
      }
      this.internalMember.Address.Category = this.category;
      const options = {
        saveFunction: this.updateContactInfo,
        updateContactInfo: true,
        payload: {
          planIdentityId: this.identityUser.PlanId,
          memberIdentityId: this.identityUser.DrawbridgeMemberId,
          payload: {
            HomePhone: phone,
            PreferredPhone: this.internalMember.PreferredPhone,
            Address: this.internalMember.Address,
            MemberSignatureBase64: this.signature?.split(",")[1],
          },
        },
      };
      console.log("internal member", this.internalMember);
      this.$emit("save", options);
      this.useCanadaPost = true;
    },
    cancel() {
      this.editMode = false;
      this.internalMember = JSON.parse(JSON.stringify(this.member));
    },
    cancelAddress() {
      this.addingAddress = false;
      this.addedAddress = false;
      this.useCanadaPost = true;
      this.outherCountry = null;
      this.fetchedAddress = this.$helpers.formatDrawbridgeAddress(
        this.member.Address
      );
    },
    formattedDateNow() {
      let day = new Date().toLocaleDateString("en-us", { day: "numeric" });
      let month = new Date().toLocaleDateString("en-us", { month: "numeric" });
      let year = new Date().toLocaleDateString("en-us", { year: "numeric" });
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return formattedDate;
    },
    formatProvince(provinceId) {
      const enumValue = this.enumModels.Province.filter(
        (o) => o.value === provinceId
      );
      if (this.$vuetify.lang.current === "en") {
        return enumValue[0]?.textEn;
      }
      return enumValue[0].textFr;
    },
    fillMemberAddress() {
      let address = this.canadaPostAddress[0];
      console.log("address to parse", address);
      const lines = address.Label.split(/\r?\n/);
      const postalCode = address.PostalCode ? address.PostalCode : "";

      if (
        this.selectedCountry.isOther ||
        this.selectedCountry?.textEn.toUpperCase() === "UNITED STATES"
      ) {
        const addressLine = lines.length > 0 ? lines[0] : null;
        const addressLine2 = lines.length > 1 ? lines[1] : null;
        const line2 = lines.length > 2 ? lines[2] : "";
        const province = address.ProvinceName;
        let addressLine3 = "";
        if (
          line2 &&
          line2.toUpperCase().includes(address?.CountryName?.toUpperCase())
        ) {
          addressLine3 = line2
            .toUpperCase()
            .replace(address?.CountryName?.toUpperCase(), "");
        } else {
          addressLine3 = line2;
        }

        if (
          !addressLine.includes(postalCode) &&
          !addressLine2.includes(postalCode) &&
          !addressLine3.includes(postalCode)
        ) {
          addressLine3 = addressLine3
            ? addressLine3 + " " + postalCode
            : postalCode;
        }

        if (
          !addressLine?.toUpperCase().includes(province?.toUpperCase()) &&
          !addressLine2?.toUpperCase().includes(province?.toUpperCase()) &&
          !addressLine3?.toUpperCase().includes(province?.toUpperCase())
        ) {
          addressLine3 = addressLine3
            ? addressLine3 + " " + province
            : province;
        }
        this.internalMember.Address = {
          StreetNumber: address.BuildingNumber,
          StreetName: address.Street,
          City: address.City,
          State:
            this.selectedCountry?.code === "USA" ? address.ProvinceName : null,
          OtherProvince: address.ProvinceName,
          OtherPostalCode: address.PostalCode,
          OtherCountry: this.otherCountry,
          SuiteNumber: address.SubBuilding,
          AptUnitSuite: address.SubBuilding,
          AddressLine: addressLine,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
        };
      } else {
        const province = this.enumModels.Province.filter(
          (o) => o.code === address.ProvinceCode
        );
        const { streetType, streetDirection, streetName } =
          this.getStreetTypeAndDirection(address);
        this.internalMember.Address = {
          StreetNumber: address.BuildingNumber,
          StreetName: address.Street,
          StreetNameOnly: streetName,
          StreetDirection: streetDirection,
          StreetType: streetType,
          City: address.City,
          Province:
            province.length > 0 ? province[0].value : address.ProvinceCode,
          PostalCode: address.PostalCode,
          Country: this.selectedCountry?.textEn,
          SuiteNumber: address.SubBuilding,
          AptUnitSuite: address.SubBuilding,
          AddressLine: null,
        };
      }
    },

    addDataToMemberAddress() {
      let address = this.internalMember.Address;
      console.log("address to parse addd", address);
      const province = this.enumModels.Province.find(
        (o) => o.value.toUpperCase() === address.Province?.toUpperCase()
      );
      this.selectedCountry = this.enumModels.Country.find(
        (o) => o.value.toUpperCase() === address.Country?.toUpperCase()
      );
      console.log("province", province, "country", this.selectedCountry);
      if (this.selectedCountry?.code === "CAN") {
        const { streetType, streetDirection, streetName } =
          this.getStreetTypeAndDirectionString(address.StreetName);
        this.internalMember.Address.StreetNameOnly = streetName;
        this.internalMember.Address.StreetDirection = streetDirection;
        this.internalMember.Address.StreetType = streetType;
        this.internalMember.Address.Country = this.selectedCountry?.textEn;
        this.internalMember.Address.Province = province?.value;
        this.internalMember.Address.ProvinceCode = province?.code;
        this.internalMember.Address.ProvinceName = province?.textEn;
        this.internalMember.Address.ProvinceId = province?.value;
      } else {
        this.internalMember.Address.State =
          this.selectedCountry?.code === "CAN" ? null : address.OtherProvince;
        const suiteNumber = this.internalMember.Address?.SuiteNumber
          ? this.internalMember.Address?.SuiteNumber + " "
          : "";
        const streetNumber = this.internalMember.Address?.StreetNumber
          ? this.internalMember.Address?.StreetNumber + " "
          : "";
        this.internalMember.Address.AddressLine =
          suiteNumber + streetNumber + address.StreetName;
      }
      this.internalMember.Address.AptUnitSuite =
        this.internalMember.Address?.SuiteNumber;
    },

    getStreetTypeAndDirectionString(street) {
      const streets = this.$helpers.parseStreetCanada(street);
      console.log("streets", streets);
      const streetType = streets[0]?.streetType;
      const { streetName, streetDirection } =
        this.$helpers.cleanUpStreetName(street);
      console.log(
        "cleaned up streetName:",
        streetName,
        "streetType:",
        streetType,
        "streetDirection:",
        streetDirection
      );
      return { streetName, streetType, streetDirection };
    },
    getStreetTypeAndDirection(address) {
      const streets = this.$helpers.parseStreetByCountryAndLanguage(
        address,
        this.selectedCountry
      );
      const streetType = streets[0]?.streetType;
      const { streetName, streetDirection } = this.$helpers.cleanUpStreetName(
        address?.Street
      );
      console.log(
        "cleaned up streetName:",
        streetName,
        "streetType:",
        streetType,
        "streetDirection:",
        streetDirection
      );
      return { streetName, streetType, streetDirection };
    },
    formatPhone(v) {
      return masker(v, this.telephoneMask, true, tokens);
    },
    onFocus() {
      this.$refs.addressList.isMenuActive = true;
    },
  },
};
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--selection-controls__ripple {
  margin-right: 0px !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.border-box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
}
.address {
  white-space: pre-wrap;
  max-width: 300px;
}
.link {
  text-decoration: underline;
  text-transform: lowercase !important;
  min-width: 0 !important;
}
</style>
