<template>
  <v-dialog
    v-model="modal"
    ref="dialog"
    :return-value.sync="dateModel"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateModel"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        :required="required"
        :class="{ required }"
        :rules="rules"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker :value="dateModel" @input="handleInput">
      <v-spacer></v-spacer>
      <v-btn text @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" @click="handleDialogOK">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { formatDateStr } from "@/utils/formatDateStr";

export default Vue.extend({
  props: {
    required: {
      type: Boolean,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      dateModel: formatDateStr(this.value),
    };
  },
  watch: {
    value(newValue) {
      if (newValue === "") {
        this.dateModel = "";
      }
    },
    dateModel(newValue) {
      console.log(newValue);
      const date = new Date(newValue);
      if (date === "") {
        this.$emit("input", "");
      } else {
        // local to UTC time conversion
        const utc = new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes() + date.getTimezoneOffset()
          )
        );
        this.$emit("input", new Date(utc).toUTCString());
      }
    },
  },
  methods: {
    handleInput(v) {
      this.dateModel = v;
    },
    handleDialogOK() {
      const dialog = this.$refs.dialog;
      if (dialog) {
        dialog.save(this.dateModel);
      }
    },
  },
});
</script>
