<template>
  <div>
    <v-container fluid class="pa-0">
      <v-data-table
        class="beneficiaries-table"
        :headers="allowedHeaders"
        :items="internalMember.Dependants"
        :item-class="rowColorClass"
        hide-default-footer
        mobile-breakpoint="960"
      >
        <template v-slot:[`item.FirstName`]="{ item }">
          <td class="text-subtitle-2 text-center">
            {{
              item.MiddleName
                ? $helpers.capitalize(
                    item.FirstName + " " + item.MiddleName + " " + item.LastName
                  )
                : $helpers.capitalize(item.FirstName + " " + item.LastName)
            }}
          </td>
        </template>

        <template v-slot:[`item.DateOfBirth`]="{ item }">
          <td class="text-subtitle-2 text-center">
            {{ $helpers.formatDate(item.DateOfBirth) }}
          </td>
        </template>
        <template v-slot:[`item.Gender`]="{ item }">
          <td class="text-subtitle-2 text-center">
            {{
              $helpers.formatEnumModel(
                item.Gender,
                "Gender",
                $vuetify.lang.current
              )
            }}
          </td>
        </template>
        <template v-slot:[`item.Relationship`]="{ item }">
          <td v-if="item.Relationship?.Id" class="text-subtitle-2 text-center">
            {{
              $helpers.formatDrawbridgeEnumModel(
                item?.Relationship?.Id,
                "Relationship",
                $vuetify.lang.current
              )
            }}
          </td>
          <td v-else class="text-subtitle-2 text-center">
            {{
              $helpers.formatDrawbridgeEnumModel(
                item?.Relationship,
                "Relationship",
                $vuetify.lang.current
              )
            }}
          </td>
        </template>
        <template v-slot:[`item.Eligible`]="{ item }">
          <td class="px-0 text-center">
            <v-icon color="success" v-if="item.Eligible && !item.IsRemoved">
              mdi-check-circle
            </v-icon>
            <div
              v-if="
                item.Eligible !== false &&
                item.Eligible == true &&
                !item.IsRemoved
              "
              class="primary--text"
            ></div>
            <div
              v-if="item.Eligible === false && !item.IsRemoved"
              class="primary--text"
            >
              {{ $t("globals.no") }}
            </div>
            <div v-if="item.IsRemoved" class="secondary--text text--lighten-4">
              {{ $t("globals.removed") }}
            </div>
          </td>
        </template>

        <template v-slot:[`item.Qualification`]="{ item }">
          <td class="px-0 text-center">
            <v-row justify="center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-if="item.Qualification?.ProofOfCommonLawStatus"
                    @click="
                      previewProof(
                        item.Qualification.ProofOfCommonLawStatus,
                        $t('components.dependants_form.proof_of_common_law')
                      );
                      loadingCommonLawProof = true;
                      currentItemId = item.Id;
                    "
                  >
                    <v-icon
                      v-if="!loadingCommonLawProof || currentItemId !== item.Id"
                      v-bind="attrs"
                      v-on="on"
                      color="secondary lighten-4"
                    >
                      mdi-home-heart
                    </v-icon>
                    <v-progress-circular v-else indeterminate size="24" />
                  </v-btn>
                </template>
                <span>{{
                  $t(`components.dependants_form.proof_of_common_law`)
                }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-if="item.Qualification?.ProofOfLifeEvent"
                    @click="
                      previewProof(
                        item.Qualification.ProofOfLifeEvent,
                        $t('components.dependants_form.proof_of_life_event')
                      );
                      loadingLifeEventProof = true;
                      currentItemId = item.Id;
                    "
                  >
                    <v-icon
                      v-if="!loadingLifeEventProof || currentItemId !== item.Id"
                      v-bind="attrs"
                      v-on="on"
                      color="secondary lighten-4"
                    >
                      mdi-mother-heart
                    </v-icon>
                    <v-progress-circular v-else indeterminate size="24" />
                  </v-btn>
                </template>
                <span>{{
                  $t(`components.dependants_form.proof_of_life_event`)
                }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-if="item.Qualification?.ProofOfSchooling"
                    @click="
                      previewProof(
                        item.Qualification.ProofOfSchooling,
                        $t('components.dependants_form.proof_of_schooling')
                      );
                      loadingSchoolingProof = true;
                      currentItemId = item.Id;
                    "
                  >
                    <v-icon
                      v-if="!loadingSchoolingProof || currentItemId !== item.Id"
                      v-bind="attrs"
                      v-on="on"
                      color="secondary lighten-4"
                    >
                      mdi-school
                    </v-icon>
                    <v-progress-circular v-else indeterminate size="24" />
                  </v-btn>
                </template>
                <span>{{
                  $t(`components.dependants_form.proof_of_schooling`)
                }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-if="item.Qualification?.ProofOfDisability"
                    @click="
                      previewProof(
                        item.Qualification.ProofOfDisability,
                        $t('components.dependants_form.proof_of_disability')
                      );
                      loadingDisabilityProof = true;
                      currentItemId = item.Id;
                    "
                  >
                    <v-icon
                      v-if="
                        !loadingDisabilityProof || currentItemId !== item.Id
                      "
                      v-bind="attrs"
                      v-on="on"
                      color="secondary lighten-4"
                    >
                      mdi-wheelchair
                    </v-icon>
                    <v-progress-circular v-else indeterminate size="24" />
                  </v-btn>
                </template>
                <span>{{
                  $t(`components.dependants_form.proof_of_disability`)
                }}</span>
              </v-tooltip>
            </v-row>
          </td>
        </template>

        <template v-slot:[`item.Actions`]="{ item }">
          <td
            class="text-subtitle-1 font-weight-bold text-right px-0"
            style="width: 80px"
          >
            <v-btn
              icon
              color="primary"
              @click="editDependant(item)"
              :disabled="item.IsRemoved"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
              @click="confirmDeleteDependant(item)"
              :disabled="item.IsRemoved"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <v-row
            no-gutters
            justify="space-between"
            class="ma-2"
            v-if="canEditDependants"
          >
            <v-btn text @click="add" class="ml-2">
              <v-icon>mdi-plus-circle-outline</v-icon>
              {{ $t("globals.add") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isRegistrationCompletion"
              :disabled="saving"
              text
              @click="resetAllDependants"
              color="secondary"
            >
              <v-icon>mdi-reload</v-icon>
              {{ $t("globals.reset_all") }}
            </v-btn>
            <!-- <v-btn
              :disabled="saving"
              text
              @click="confirmDeleteAllDependants"
              color="primary"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
              {{ $t("globals.remove_all") }}
            </v-btn> -->
          </v-row>
          <v-row
            v-if="
              (!internalMember.Dependants ||
                internalMember.Dependants.length === 0) &&
              isRegistrationCompletion
            "
          >
            <v-col cols="auto" class="pa-4 pt-0">
              <v-checkbox
                v-model="valid"
                inset
                class="mt-0 ml-2"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col class="pa-4 text-left pt-0" align-self="center">
              {{
                $t(
                  "components.dependant_expansion_panel.acknowledge_dependant_proof"
                )
              }}
            </v-col>
          </v-row>
          <v-row no-gutters v-if="dependantDefinition">
            <v-col
              class="pa-4 text-left text-caption"
              v-html="
                $vuetify.lang.current === 'fr'
                  ? dependantDefinition.FR
                  : dependantDefinition.EN
              "
            ></v-col>
          </v-row>
        </template>
      </v-data-table>
      <v-overlay :value="saving && !savingImage" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- Add / Edit Dialog -->
      <v-dialog max-width="1000" v-model="editDialog" persistent>
        <dependant-form
          :minEffectiveDate="internalMember?.PlanEntryDate"
          :member="internalMember"
          :dependant="selectedDependant"
          :dependantSaved="dependantSaved"
          :dependants="internalMember.Dependants"
          :isRegistrationCompletion="isRegistrationCompletion"
          :saving="savingDependant"
          :title="title"
          :titleIcon="titleIcon"
          @cancel-dialog="
            editDialog = false;
            selectedDependant = {};
            editDialog = false;
          "
          @save-dependant="saveDependant"
        ></dependant-form>
      </v-dialog>

      <!-- Confirm delete Dialog -->
      <v-dialog max-width="800" v-model="deleteDialog" persistent>
        <delete-dependant-dialog
          :minEffectiveDate="internalMember?.CoverageEffectiveDate"
          :member="internalMember"
          :dependant="selectedDependant"
          :deleteAll="deleteAll"
          :dependantSaved="dependantSaved"
          :dialogMessage="dialogMessage"
          :isRegistrationCompletion="isRegistrationCompletion"
          :saving="savingDependant"
          :title="title"
          @delete-dependant="deleteDependantHandler"
          @delete-all="deleteAllDependantsHandler"
          @cancel-dialog="
            deleteDialog = false;
            deleteAll = false;
            editDialog = false;
          "
        ></delete-dependant-dialog>
      </v-dialog>
    </v-container>

    <file-preview-base64
      :fullScreen="false"
      v-if="preview"
      :view="preview"
      :fileItems="fileItems"
      @close="closePreview"
    />

    <error-handler absolute :error="error"></error-handler>

    <v-snackbar absolute v-model="snackbar" color="success" :timeout="timeout">
      {{ successMessage }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ErrorHandler from "@/components/ErrorHandler.vue";
import DependantForm from "@/components/MemberInformation/items/panels/DependantsPanel/DependantForm.vue";
import DeleteDependantDialog from "@/components/MemberInformation/items/panels/DependantsPanel/DeleteDependantDialog.vue";
import FilePreviewBase64 from "@/components/controls/FilePreviewBase64.vue";
export default {
  props: {
    member: Object,
    saving: Boolean,
    savingImage: Boolean,
    memberSaved: Boolean,
    isRegistrationCompletion: Boolean,
  },
  components: {
    ErrorHandler,
    DependantForm,
    DeleteDependantDialog,
    FilePreviewBase64,
  },
  data() {
    return {
      dateMenu: false,
      internalMember: {},
      dependantsBeforeSaved: null,
      dependantsBeforeEdited: null,
      valid: false,
      editDialog: false,
      deleteDialog: false,
      selectedDependant: {},
      deleteAll: false,
      dialogMessage: "",
      title: "",
      titleIcon: "",
      successMessage: "",
      error: undefined,
      snackbar: false,
      timeout: -1,
      savingDependant: false,
      dependantSaved: false,
      loadingCommonLawProof: false,
      loadingLifeEventProof: false,
      loadingSchoolingProof: false,
      loadingDisabilityProof: false,
      currentItemId: null,
      preview: false,
      fileItems: null,
      newBeneficiary: {
        BeneficiaryType: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Relationship: "",
        BeneficiarySIN: null,
        DateOfBirth: "",
        Gender: "",
        LanguagePreference: "",
        Percentage: null,
      },
    };
  },
  computed: {
    ...mapGetters("common", [
      "enumModels",
      "dependantDefinition",
      "canEditDependants",
      "adminEmailRecipients",
    ]),
    ...mapGetters("auth", ["user"]),
    remainingPercentage() {
      let percent = 100;
      if (this.internalMember && this.internalMember.Beneficiaries)
        this.internalMember.Beneficiaries.forEach(
          (b) => (percent = percent - b.Percentage)
        );
      return percent;
    },
    allowedHeaders() {
      if (this.canEditDependants) return this.headers;
      else return this.headers.filter((h) => h.value !== "Actions");
    },
    headers() {
      return [
        {
          text: this.$t("components.beneficiary_expansion_panel.headers.name"),
          align: "center",
          sortable: false,
          value: "FirstName",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t(
            "components.beneficiary_expansion_panel.headers.relationship"
          ),
          align: "center",
          sortable: false,
          value: "Relationship",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2",
        },
        {
          text: this.$t("components.beneficiary_expansion_panel.headers.dob"),
          align: "center",
          sortable: false,
          value: "DateOfBirth",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2",
        },
        {
          text: this.$t("components.beneficiary_form.gender"),
          align: "center",
          sortable: false,
          value: "Gender",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2",
        },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.eligible"
          ),
          align: "center",
          sortable: false,
          value: "Eligible",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2",
        },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.proof"
          ),
          align: "center",
          sortable: false,
          value: "Qualification",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2",
        },
        {
          text: this.$t("globals.actions"),
          value: "Actions",
          align: "center",
          sortable: false,
          class: "secondary--text text-uppercase text-subtitle-2",
        },
      ];
    },
  },
  watch: {
    member: {
      handler(v) {
        this.internalMember = v;
      },
      deep: true,
    },
    valid: {
      handler(v) {
        console.log(v);
        if (this.internalMember.Dependants.length === 0)
          this.$emit("update-valid", v);
      },
    },
    "internalMember.Dependants": {
      handler(v) {
        console.log(v);
        if (v.length > 0) {
          this.valid = false;
          this.$emit("update-valid", true);
        } else if (!this.valid) {
          this.$emit("update-valid", false);
        }
      },
    },
  },
  mounted() {
    this.internalMember = JSON.parse(JSON.stringify(this.member));
    this.dependantsBeforeEdited = JSON.parse(
      JSON.stringify(this.internalMember.Dependants)
    );
  },
  methods: {
    ...mapActions("common", ["loadUserProfileData"]),
    ...mapActions("data", [
      "addDependant",
      "updateDependant",
      "deleteDependant",
      "deleteAllDependants",
      "getFile",
    ]),
    edit() {
      // console.log("edit");
    },
    add() {
      this.title = this.$t("components.dependants_form.title.add");
      this.titleIcon = "mdi-account-plus-outline";
      console.log("add");
      this.selectedDependant = {};
      this.selectedDependant.IsNew = true;
      this.selectedDependant.UniqueIdentifier = this.$helpers.randomID();
      this.selectedDependant.Action = "Create";
      this.editDialog = true;
    },
    saveInCompleteRegistration() {
      if (this.isRegistrationCompletion) {
        this.dependantSaved = true;
        this.editDialog = false;
        this.deleteAll = false;
        this.deleteDialog = false;
        this.$emit("save-dependants", this.internalMember.Dependants);
      }
    },
    cancel() {
      console.log("cancel");
      this.editDialog = false;
      this.deleteDialog = false;
      this.internalMember = JSON.parse(JSON.stringify(this.member));
      if (this.dependantsBeforeSaved !== null) {
        this.internalMember.Dependants = JSON.parse(
          JSON.stringify(this.dependantsBeforeSaved)
        );
        this.dependantsBeforeSaved = null;
      }
      this.selectedDependant = {};
    },
    rowColorClass(item) {
      if (item.IsRemoved) return "grey lighten-2";
      else return "";
    },
    async deleteDependantHandler(dependant) {
      this.savingDependant = true;
      this.dependantSaved = false;
      console.log("deleting dependant", dependant.Id);
      dependant.Relationship = dependant?.Relationship?.Id
        ? dependant?.Relationship?.Id
        : dependant?.Relationship;
      dependant.EffectiveDate =
        dependant?.EffectiveDate.split("T")[0] + "T00:00:00.000Z";
      dependant.DateOfBirth =
        dependant?.DateOfBirth?.split("T")[0] + "T00:00:00.000Z";
      const adminRecipients =
        this.$vuetify.lang.current === "fr"
          ? this.adminEmailRecipients.Fr
          : this.adminEmailRecipients.En;
      try {
        if (!this.isRegistrationCompletion) {
          this.successMessage = this.$t(
            "components.dependant_expansion_panel.delete_success"
          );
          await this.deleteDependant({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.drawbridgeMemberId,
            payload: {
              Dependant: dependant,
              Recipients: adminRecipients,
              Language: this.$vuetify.lang.current,
              MemberSignatureBase64: dependant.Signature?.split(",")[1],
            },
          });
          this.dependantSaved = true;
          this.snackbar = true;
        }
        const filtered = this.internalMember.Dependants.filter((b) => {
          if (b?.Id && b?.Id === dependant?.Id) return false;
          else if (
            b?.UniqueIdentifier &&
            b?.UniqueIdentifier === dependant?.UniqueIdentifier
          )
            return false;
          else return true;
        });
        if (this.isRegistrationCompletion) {
          if (!dependant.IsNew) {
            const effectiveDate = this.getCorrectEffectiveDate(
              // this.$helpers.formattedDateNow() + "T00:00:00.000Z",
              this.internalMember?.PlanEntryDate,
              dependant.DateOfBirth
            );
            dependant.EffectiveDate = effectiveDate;
            filtered.push(dependant);
          }
        }
        filtered.sort((a, b) => {
          return new Date(a.DateOfBirth) - new Date(b.DateOfBirth);
        });
        this.internalMember.Dependants = filtered;
        if (!this.isRegistrationCompletion) {
          await this.loadUserProfileData({
            planId: this.user.plan,
            userId: this.user.sub,
          });
          this.editDialog = false;
        }
        this.saveInCompleteRegistration();
      } catch (error) {
        console.log(error);
        this.error = {
          customMessage: this.$t(
            "components.dependant_expansion_panel.delete_error"
          ),
        };
      } finally {
        this.savingDependant = false;
      }
    },
    async deleteAllDependantsHandler(effectiveDateFromForm) {
      this.savingDependant = true;
      this.dependantSaved = false;
      console.log("deleting all dependants");
      try {
        if (!this.isRegistrationCompletion) {
          this.successMessage = this.$t(
            "components.dependant_expansion_panel.delete_all_success"
          );
          await this.deleteAllDependants({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.drawbridgeMemberId,
            payload: effectiveDateFromForm,
          });
          this.dependantSaved = true;
          this.snackbar = true;
        }
        const effectiveDate = this.getCorrectEffectiveDate(
          this.$helpers.formattedDateNow() + "T00:00:00.000Z",
          this.internalMember.PlanEntryDate
        );
        this.internalMember.Dependants = this.internalMember.Dependants.map(
          (d) => {
            d.IsRemoved = true;
            if (this.isRegistrationCompletion) {
              d.EffectiveDate = effectiveDate;
              // d.EffectiveDate =
              //   this.$helpers.formattedDateNow() + "T00:00:00.000Z";
              d.Relationship = d?.Relationship?.Id
                ? d?.Relationship?.Id
                : d?.Relationship;
            }
            return d;
          }
        );
        this.deleteAll = false;
        this.saveInCompleteRegistration();
      } catch (error) {
        console.log(error);
        this.error = {
          customMessage: this.$t(
            "components.dependant_expansion_panel.delete_all_error"
          ),
        };
      } finally {
        this.savingDependant = false;
      }
    },
    confirmDeleteDependant(dependant) {
      this.title = this.$t("components.delete_dependant_dialog.title_{0}", [
        dependant.FirstName + " " + dependant.LastName,
      ]);
      this.dialogMessage = this.$t(
        "components.delete_dependant_dialog.are_you_sure_{0}",
        [dependant.FirstName + " " + dependant.LastName]
      );
      this.selectedDependant = dependant;
      this.deleteDialog = true;
    },
    confirmDeleteAllDependants() {
      this.title = this.$t("components.delete_all_dependants_dialog.title");
      this.dialogMessage = this.$t(
        "components.delete_all_dependants_dialog.are_you_sure"
      );
      this.deleteAll = true;
      this.deleteDialog = true;
    },
    editDependant(dependant) {
      this.title = this.$t("components.dependant_form.title.edit_{0}", [
        dependant.FirstName + " " + dependant.LastName,
      ]);
      this.titleIcon = "mdi-account-edit-outline";
      this.selectedDependant = dependant;
      if (!this.selectedDependant?.IsNew) {
        this.selectedDependant.Action = "Update";
      }
      this.editDialog = true;
    },
    async saveDependant(dependant) {
      console.log("saving dependant", dependant);
      this.dependantsBeforeSaved = JSON.parse(
        JSON.stringify(this.internalMember.Dependants)
      );
      this.savingDependant = true;
      this.dependantSaved = false;
      dependant.EffectiveDate =
        dependant.EffectiveDate.split("T")[0] + "T00:00:00.000Z";
      dependant.DateOfBirth =
        dependant.DateOfBirth.split("T")[0] + "T00:00:00.000Z";
      // console.log(
      //   "saving dependant",
      //   dependant,
      //   "isRegistrationCompletion",
      //   this.isRegistrationCompletion
      // );
      if (dependant.CobEffectiveDate) {
        dependant.CobEffectiveDate =
          dependant.CobEffectiveDate.split("T")[0] + "T00:00:00.000Z";
      }
      const adminRecipients =
        this.$vuetify.lang.current === "fr"
          ? this.adminEmailRecipients.Fr
          : this.adminEmailRecipients.En;

      try {
        if (!this.isRegistrationCompletion) {
          if (dependant.IsNew) {
            this.successMessage = this.$t(
              "components.dependant_expansion_panel.new_save_success"
            );
            const response = await this.addDependant({
              planIdentityId: this.user.plan,
              memberIdentityId: this.user.drawbridgeMemberId,
              payload: {
                Dependant: dependant,
                Recipients: adminRecipients,
                Language: this.$vuetify.lang.current,
                MemberSignatureBase64: dependant.Signature?.split(",")[1],
              },
            });
            dependant.Id = response?.data?.Id;
            dependant.IsNew = false;
          } else {
            this.successMessage = this.$t(
              "components.dependant_expansion_panel.update_save_success"
            );
            await this.updateDependant({
              planIdentityId: this.user.plan,
              memberIdentityId: this.user.drawbridgeMemberId,
              payload: {
                Dependant: dependant,
                Recipients: adminRecipients,
                Language: this.$vuetify.lang.current,
                MemberSignatureBase64: dependant.Signature?.split(",")[1],
              },
            });
          }
          this.dependantSaved = true;
          this.snackbar = true;
        }
        const filtered = this.internalMember.Dependants.filter((b) => {
          if (b?.Id && b?.Id === dependant?.Id) return false;
          else if (
            b?.UniqueIdentifier &&
            b?.UniqueIdentifier === dependant?.UniqueIdentifier
          )
            return false;
          else return true;
        });
        console.log("filtered", JSON.parse(JSON.stringify(filtered)));
        if (this.isRegistrationCompletion) {
          const effectiveDate = this.getCorrectEffectiveDate(
            // this.$helpers.formattedDateNow() + "T00:00:00.000Z",
            this.internalMember?.PlanEntryDate,
            dependant.DateOfBirth
          );
          dependant.EffectiveDate = effectiveDate;
          // dependant.EffectiveDate = dependant.EffectiveDate =
          //   this.$helpers.formattedDateNow() + "T00:00:00.000Z";
        }
        filtered.push(dependant);
        filtered.sort((a, b) => {
          return new Date(a.DateOfBirth) - new Date(b.DateOfBirth);
        });
        this.internalMember.Dependants = filtered;
        if (!this.isRegistrationCompletion) {
          await this.loadUserProfileData({
            planId: this.user.plan,
            userId: this.user.sub,
          });
          this.editDialog = false;
        }
        this.saveInCompleteRegistration();
      } catch (error) {
        console.log(error);
        this.error = {
          customMessage: this.$t("components.dependant_expansion_panel.error"),
        };
      } finally {
        this.savingDependant = false;
      }
    },

    getCorrectEffectiveDate(planEntryDate, dependantDob) {
      // console.log(
      //   "planEntryDate is",
      //   planEntryDate,
      //   "and dependantDob is",
      //   dependantDob
      // );
      const planDate = new Date(planEntryDate);
      const dependantDate = new Date(dependantDob);
      if (planDate < dependantDate) {
        // console.log("planDate < dependantDob and is", dependantDob);
        return dependantDob?.split("T")[0] + "T00:00:00.000Z";
      } else {
        // console.log("planEntry > coverageDate and is", planEntryDate);
        return planEntryDate?.split("T")[0] + "T00:00:00.000Z";
      }
    },

    resetAllDependants() {
      this.internalMember.Dependants = JSON.parse(
        JSON.stringify(this.dependantsBeforeEdited)
      );
    },

    async previewProof(proof, proofName) {
      this.loadingProof = true;
      proof.UploadName = proofName;

      const getFileBase64 = (fileSegment) => {
        // eslint-disable-next-line no-async-promise-executor, no-unused-vars
        return new Promise(async (resolve, reject) => {
          let file = (
            await this.getFile({
              planIdentityId: this.user.plan,
              physicalFileId: fileSegment.PhysicalFileId,
            })
          ).data;
          if (file[0] === '"') file = file.slice(1, -1);
          fileSegment.Base64 = file;
          fileSegment.FileExtension = this.$helpers.fileExtension(
            fileSegment.FileName
          );
          resolve(fileSegment);
        });
      };

      try {
        await Promise.all(proof.FileSegments.map((fs) => getFileBase64(fs)));
        this.fileItems = proof;
        if (proof.FileSegments[0]?.Base64) {
          this.preview = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCommonLawProof = false;
        this.loadingLifeEventProof = false;
        this.loadingSchoolingProof = false;
        this.loadingDisabilityProof = false;
      }
    },

    closePreview() {
      this.preview = false;
      this.fileItems = null;
    },
  },
};
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--selection-controls__ripple {
  margin-right: 0px !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.beneficiaries-table >>> th {
  padding: 0 5px !important;
}
.beneficiaries-table >>> td {
  padding: 0 2px !important;
}
</style>
