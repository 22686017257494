<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card>
        <v-card-title>Add Qualification</v-card-title>
        <v-card-text>
          <v-form
            id="qualification"
            ref="form"
            v-model="isValid"
            @submit.prevent="createMemberQualification()"
          >
            <v-row>
              <v-col>
                <v-text-field
                  label="Ticket Number"
                  v-model="internal.ticketNumber"
                  :rules="formRules"
                  class="required"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-file-input
                  label="File"
                  v-model="file"
                  :required="isFileRequired"
                  :rules="fileRequiredRules"
                  :class="classRequired"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Qualification"
                  v-model="internal.qualificationId"
                  item-value="Id"
                  item-text="Name"
                  :items="qualifications"
                  @change="preSelectQualification(internal.qualificationId)"
                  class="required"
                  required
                  :rules="formRules"
                  clearable
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Level"
                  v-model="internal.levelId"
                  item-value="Id"
                  item-text="Name"
                  :items="levels"
                  class="required"
                  required
                  :rules="formRules"
                  clearable
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DatePickerInput
                  v-model="internal.startDate"
                  :rules="formRules"
                  required
                  label="Start Date"
                  :value="internal.startDate"
                />
              </v-col>
              <v-col>
                <DatePickerInput
                  v-model="internal.expiryDate"
                  :value="internal.expiryDate"
                  :rules="[expiryRule]"
                  :required="isExpiryRequired"
                  label="Expiry Date"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <slot v-bind="{ form: $refs.form }">
            <v-spacer></v-spacer>
            <v-btn plain @click="closeModal()">Cancel</v-btn>

            <v-btn
              type="submit"
              :formId="title"
              form="qualification"
              :disabled="!isValid"
              color="primary"
              v-bind="$attrs"
              v-on="$listeners"
              >Submit</v-btn
            >
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDelete" max-width="600">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text> This action cannot be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="capitalize-text" @click="showDelete = false">
            Cancel
          </v-btn>
          <v-btn
            :loading="loading"
            color="error"
            class="capitalize-text"
            @click="confirmDelete"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-card-sba
      :size.sync="internalSize"
      :height.sync="internalHeight"
      :order.sync="internalOrder"
      :maxOrderValue="maxOrderValue"
      :widgetId="widgetId"
      widgetIcon="mdi-star"
      @remove-widget="$emit('remove-widget', $event)"
      @add-widget="$emit('add-widget', $event)"
      :color="`primary`"
      title="Qualifications"
    >
      <div style="width: 100%; display: flex">
        <v-btn
          style="margin-left: auto; justify-self: end"
          class="mt-3 mr-3"
          color="primary"
          @click="show = true"
          >+ Add New</v-btn
        >
      </div>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        class="table"
      >
        <template v-slot:[`item.ExpiryDate`]="{ item }">
          <td class="text-subtitle-2 text-left">
            {{ $helpers.formatDate(item.ExpiryDate) }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="openDeleteModal(item.Id)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </material-card-sba>
  </div>
</template>

<script>
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service";
import DatePickerInput from "@/components/Common/DatePickerInputComponent.vue";

export default {
  components: { MaterialCardSba, DatePickerInput },
  props: {
    widgetId: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  computed: {
    ...mapGetters("common", ["union"]),
    ...mapGetters("auth", ["user"]),

    isValidExpiry() {
      return (
        new Date(this.internal.expiryDate).getTime() >
        new Date(this.internal.startDate).getTime()
      );
    },
    requiredRule() {
      return (v) => !!v || "Expiry date is required";
    },
    expiryRule() {
      return () => {
        if (!this.internal.expiryDate) return true;
        if (this.isValidExpiry) return true;
        return "Expiry date must be after start date";
      };
    },

    formRules() {
      return [(v) => !!v || "Field is required"];
    },
    isExpiryRequired() {
      return this.qualification.Expires || false;
    },
    isFileRequired() {
      return this.qualification.FileRequired || false;
    },
    fileRequiredRules() {
      return [
        (v) => {
          if (this.isFileRequired && !v) {
            return "File is required";
          }
          return true;
        },
      ];
    },
    expiryRules() {
      return [
        (v) => {
          if (this.isExpiryRequired && !v) {
            return "Expiry is required";
          }
          return true;
        },
      ];
    },
    classRequired() {
      if (this.isFileRequired) {
        return "required";
      } else {
        return "";
      }
    },
  },
  async mounted() {
    await this.load();
  },
  data() {
    return {
      isValid: false,
      show: false,
      file: null,
      showDelete: false,
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      internal: {
        qualificationId: "",
        fileId: "",
        levelId: "",
        ticketNumber: "",
        startDate: "",
        expiryDate: "",
      },
      loading: false,
      headers: [
        {
          text: "Name",
          value: "Qualification.Name",
        },

        {
          text: "Level",
          value: "Level.Name",
        },
        {
          text: "Expiry Date",
          value: "ExpiryDate",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      items: [],
      qualifications: [],
      qualification: {},
      levels: [],
      memberQualificationToDelete: "",
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;
        const resp = await ApiService.get(`${url}qualifications`);
        await this.getAllLevelQualifications();
        await this.getAllQualifications();

        this.items = resp.data;
      } finally {
        this.loading = false;
      }
    },
    async getAllQualifications() {
      const url = SettingsService.settings.DISPATCHURL;
      const resp = await ApiService.get(`${url}allQualifications`);
      if (this.qualifications.length > 0) {
        this.qualification = this.qualifications[0];
      }
      this.qualifications = resp.data;
    },
    async getAllLevelQualifications() {
      const url = SettingsService.settings.DISPATCHURL;
      const resp = await ApiService.get(`${url}qualificationLevels`);
      this.levels = resp.data;
    },
    async openDeleteModal(qId) {
      this.showDelete = true;
      this.memberQualificationToDelete = qId;
    },
    async preSelectQualification(qualId) {
      const foundQualification = this.qualifications.find(
        (qual) => qual.Id === qualId
      );
      const form = this.$refs.form;
      form.resetValidation();
      this.qualification = foundQualification;
    },
    async createMemberQualification() {
      const form = this.$refs.form;
      if (!form.validate()) {
        return;
      }
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;
        let formData = new FormData();
        this.fileId = "";

        if (this.file) {
          formData.append("file", this.file);
          const fileRes = await ApiService.postFile(
            `${url}qualification/file`,
            formData
          );
          this.internal.fileId = fileRes.data.Id;
        }
        await ApiService.post(`${url}qualifications`, this.internal);
        await this.load();
      } finally {
        this.loading = false;
        this.show = false;
      }
    },
    async confirmDelete() {
      try {
        const url = SettingsService.settings.DISPATCHURL;
        await ApiService.delete(
          `${url}qualifications/${this.memberQualificationToDelete}`
        );
        await this.load();
      } finally {
        this.loading = false;
        this.showDelete = false;
      }
    },
    async closeModal() {
      this.internal = {
        qualificationId: "",
        levelId: "",
        ticketNumber: "",
        startDate: "",
        expiryDate: "",
        fileId: "",
      };
      this.file = null;
      const form = this.$refs.form;
      form.resetValidation();
      if (this.qualifications.length > 0) {
        this.qualification = this.qualifications[0];
      }
      this.show = false;
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
};
</script>
