<template>
  <div>
    <v-dialog v-model="show" max-width="500">
      <v-card>
        <v-card-title>Add Safety Ticket</v-card-title>
        <v-card-text>
          <v-form
            id="tickets"
            ref="form"
            v-model="isValid"
            @submit.prevent="addMemberSafetyTicket()"
          >
            <v-row>
              <v-col>
                <v-select
                  label="Safety Ticket"
                  :items="safetyTickets"
                  item-text="Name"
                  item-value="Id"
                  v-model="ticketId"
                  required
                  :rules="formRules"
                  class="required"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-file-input
                  v-if="isFileRequired"
                  :rules="formRules"
                  class="required"
                  label="File"
                  v-model="file"
                ></v-file-input>
                <v-file-input
                  v-if="!isFileRequired"
                  label="File"
                  v-model="file"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-icon="mdi-calendar"
                      label="Start Date"
                      v-model="startDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="formRules"
                      class="required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="menuStart = false"
                    required
                    :rules="formRules"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-if="isExpiry"
                  v-model="menuExpiry"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-icon="mdi-calendar"
                      label="Expiry Date"
                      clearable
                      v-model="expiryDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[requiredRule, expiryRule]"
                      class="required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expiryDate"
                    @input="menuExpiry = false"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  v-if="!isExpiry"
                  v-model="menuExpiry"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-icon="mdi-calendar"
                      :rules="[expiryRule]"
                      clearable
                      label="Expiry Date"
                      v-model="expiryDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expiryDate"
                    @input="menuExpiry = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-col>
              <v-text-field label="Comment" v-model="comment"></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <slot v-bind="{ form: $refs.form }">
            <v-spacer></v-spacer>
            <v-btn plain @click="cancelModal()">Cancel</v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!isValid"
              :formId="title"
              form="tickets"
              v-bind="$attrs"
              v-on="$listeners"
              >Submit
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <material-card-sba
      :size.sync="internalSize"
      :height.sync="internalHeight"
      :order.sync="internalOrder"
      :maxOrderValue="maxOrderValue"
      :widgetId="widgetId"
      widgetIcon="mdi-ticket-account"
      @remove-widget="$emit('remove-widget', $event)"
      @add-widget="$emit('add-widget', $event)"
      :color="`primary`"
      title="Safety Tickets"
    >
      <div style="width: 100%; display: flex">
        <v-btn
          style="margin-left: auto; justify-self: end"
          class="mt-3 mr-3"
          color="primary"
          @click="show = true"
          >+ Add New</v-btn
        >
      </div>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        class="table"
      >
        <template v-slot:[`item.ExpiryDate`]="{ item }">
          <td class="text-subtitle-2 text-left">
            {{ $helpers.formatDate(item.ExpiryDate) }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </material-card-sba>
  </div>
</template>

<script>
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service";
// import MaterialCard from "../material/MaterialCard.vue";

export default {
  components: { MaterialCardSba },
  props: {
    widgetId: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  computed: {
    ...mapGetters("common", ["union"]),
    ...mapGetters("auth", ["user"]),
    formRules() {
      return [(v) => !!v || "Field is required"];
    },
    isValidExpiry() {
      return (
        new Date(this.expiryDate).getTime() > new Date(this.startDate).getTime()
      );
    },
    requiredRule() {
      return (v) => !!v || "Expiry date is required";
    },
    expiryRule() {
      return () => {
        if (!this.expiryDate) return true;
        if (this.isValidExpiry) return true;

        return "Expiry date must be after start date";
      };
    },
  },
  async mounted() {
    await this.load();
  },
  data() {
    return {
      isValid: false,
      show: false,
      file: null,
      menuStart: false,
      menuExpiry: false,
      isExpiry: false,
      isFileRequired: false,
      startDate: "",
      expiryDate: "",
      comment: "",
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      loading: false,
      headers: [
        {
          text: "Name",
          value: "SafetyTicket.Name",
        },
        {
          text: "Expiry Date",
          value: "ExpiryDate",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      items: [],
      safetyTickets: [],
      ticketId: "",
      ticketToDelete: "",
      fileId: "",
      dialogDelete: false,
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;
        const resp = await ApiService.get(`${url}tickets`);
        const tickets = await ApiService.get(`${url}safety-tickets`);

        this.items = resp.data;
        this.safetyTickets = tickets.data;
      } finally {
        this.loading = false;
      }
    },
    async addMemberSafetyTicket() {
      const form = this.$refs.form;
      if (!form.validate()) {
        return;
      }
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;
        let formData = new FormData();
        this.fileId = "";

        if (this.file) {
          formData.append("file", this.file);
          console.log(formData.getAll("file"));
          const fileRes = await ApiService.postFile(
            `${url}tickets/file`,
            formData
          );
          this.fileId = fileRes.data.Id;
        }

        const memberSafetydata = {
          safetyTicketId: this.ticketId,
          startDate: this.startDate,
          expiryDate: this.expiryDate,
          comment: this.comment,
          fileId: this.fileId,
        };

        await ApiService.post(`${url}tickets`, memberSafetydata);

        this.load();
      } finally {
        this.loading = false;
        this.show = false;
        this.ticketId = "";
        this.comment = "";
        this.startDate = "";
        this.expiryDate = "";
        this.fileId = "";
        this.file = null;
        form.resetValidation();
      }
    },
    cancelModal() {
      const form = this.$refs.form;
      form.resetValidation();
      this.show = false;
      this.ticketId = "";
      this.comment = "";
      this.startDate = "";
      this.expiryDate = "";
    },
    deleteItem(item) {
      this.ticketToDelete = item.Id;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        const url = SettingsService.settings.DISPATCHURL;
        await ApiService.delete(`${url}tickets/${this.ticketToDelete}`);
      } finally {
        this.dialogDelete = false;
        this.load();
      }
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
    ticketId: {
      handler(v) {
        this.safetyTickets.forEach((e) => {
          if (e.Id == v) {
            this.isExpiry = e.Expires;
            this.isFileRequired = e.FileRequired;
            console.log(this.isExpiry, this.isFileRequired);
          }
        });
      },
    },
  },
};
</script>
