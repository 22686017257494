import { render, staticRenderFns } from "./BeneficiariesExpansionPanelSBA.vue?vue&type=template&id=137bf84a&scoped=true"
import script from "./BeneficiariesExpansionPanelSBA.vue?vue&type=script&lang=js"
export * from "./BeneficiariesExpansionPanelSBA.vue?vue&type=script&lang=js"
import style0 from "./BeneficiariesExpansionPanelSBA.vue?vue&type=style&index=0&id=137bf84a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137bf84a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VChip,VCol,VContainer,VDataTable,VDialog,VDivider,VIcon,VOverlay,VProgressCircular,VRow,VSpacer,VTextField})
