/* eslint-disable prettier/prettier */
import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import AuthService from "@/services/auth.service";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function loadPreferences(userLanguage) {
  let lang = store.getters["common/language"];

  if (lang === undefined || lang === null) {
    const settingsJson = window.localStorage[`language`];

    if (settingsJson !== undefined) {
      const storageLang = JSON.parse(settingsJson);
      // console.log(storageLang);
      store.dispatch("common/setLanguage", storageLang);
      return;
    }
    if (userLanguage) {
      store.dispatch("common/setLanguageFromUrl", userLanguage);
      return;
    }
    let browserLang = navigator.language || navigator.userLanguage;
    let languages = store.getters["common/languages"];
    if (browserLang) {
      let browserLanguage = languages.filter((o) => o.language === browserLang);
      if (browserLanguage) {
        store.dispatch("common/setLanguage", browserLanguage);
        return;
      }
    }
    store.dispatch("common/setLanguage", {
      language: "en",
      title: "English",
    });
  }
}

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);

  const onlyIfAllowedToMember = to.matched.some(
    (record) => record.meta.onlyIfAllowedToMember
  );
  if (onlyIfAllowedToMember) {
    let routeItem = store.getters["common/leftDrawerMenuItems"]?.find(
      (i) => i.Url === to.path
    );
    const userProfile = store.getters["common/userProfile"];
    const user = store.getters["auth/user"];

    if (
      routeItem?.ShowTo?.includes(userProfile?.AccountStatus) === false &&
      routeItem?.ShowTo?.includes(user?.role) === false
    ) {
      console.log(
        "not allowed to access this route",
        routeItem,
        user,
        userProfile
      );
      return next({
        path: "/",
      });
    }
  }

  const isPrivateRoute = !isPublic;

  if (isPrivateRoute) console.log("the route is private");
  const onlyWhenLoggedOut = to.matched.some((record) => {
    return record.meta.onlyWhenLoggedOut;
  });

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let lang = {};

  if (urlParams.has("lang")) {
    lang.language = urlParams.get("lang");
    console.log("url setting language to", lang);
    store.dispatch("common/setLanguage", lang);
  }

  const user = await AuthService.getUser();
  console.log(user);

  //const isPensionsPortal = store.getters["common/isPensionsPortal"];
  //const portalPlanIdentityId = store.getters["common/portalPlanIdentity"];

  let isLoggedIn = false;
  if (user) {
    // console.log("user profile: ", JSON.stringify(user.profile,),);
    // console.log("portal plan", JSON.stringify(portalPlanIdentityId,),);

    // Check if member is Neospin member and still on pensions portal
    // if (isPensionsPortal && !user.profile.neospinMemberId) {
    //   console.log("user is not Neospin Member, loggin out...");
    //   store.dispatch("auth/logout");
    // }

    // Check if member is Drawbridge Member and plan is equal to portal plan (still on correct members portal)
    // if (!isPensionsPortal && portalPlanIdentityId && !user.profile.plan) {
    //   console.log("user's plan is unknown, loging out... sss");
    //   store.dispatch("auth/logout");
    // }

    // if (
    //   !isPensionsPortal &&
    //   portalPlanIdentityId &&
    //   user.profile.plan !== portalPlanIdentityId
    // ) {
    //   console.log(
    //     "user's plan does not match portalSettings.planIdentityId: user's plan: ",
    //     JSON.stringify(user.profile.plan),
    //     "portal plan:",
    //     // eslint-disable-next-line comma-dangle
    //     JSON.stringify(portalPlanIdentityId)
    //   );
    //   store.dispatch("auth/logout");
    // }

    if (!user.profile) {
      return await AuthService.startLogout("LogoutToLogin");
    }

    isLoggedIn = user !== null && !user.expired;
    store.dispatch("auth/setIsLoggedIn", isLoggedIn);
    console.log(
      "route data:",
      to,
      isLoggedIn,
      isPrivateRoute,
      // eslint-disable-next-line prettier/prettier
      onlyWhenLoggedOut
    );
  }

  if (isLoggedIn) {
    await AuthService.handleUser(user);
    if (onlyWhenLoggedOut) {
      return next("/");
    }

    loadPreferences(user.profile.language);
    console.log(isPrivateRoute, user.profile.confirmed, to.name);
    if (isPrivateRoute) {
      // if (user.profile.confirmed !== "True") {
      //   if (to.name !== "firstLogin") {
      //     return next({
      //       path: "/first-login",
      //     });
      //     // return next();
      //   }
      // }
      return next();
    }
  }

  if (isPrivateRoute) {
    // let state = {};
    // if (to.query.redirect) {
    //   // state.redirectUrl = window.location.href;
    //   state.redirectUrl = window.location.href;
    // }
    // eslint-disable-next-line no-undef
    console.log("redirect to login");
    // eslint-disable-next-line no-undef
    return AuthService.login();
    // return next({
    //   path: "/login",
    // });
  } else if (isPublic) {
    return next();
  }
});

export default router;
