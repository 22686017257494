<template>
  <div>
    <v-card class="pa-0" flat width="1200">
      <v-card-title class="justify-center primary--text pb-0 pb-sm-5">{{
        $t("components.registration_completion.page_title")
      }}</v-card-title>
      <!-- <v-card-text class="text-subtitle-1 py-0">{{
          $t("components.registration_completion.page_subtitle")
        }}</v-card-text> -->
      <v-card-text class="pa-0">
        <v-stepper v-model="step" vertical flat v-if="internalMember">
          <v-stepper-step
            step="1"
            :complete="step1Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step1_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion.page_subtitle") }}
              <span class="custom-underline primary--text" @click="contactUs">{{
                $t("components.registration_completion.page_subtitle1")
              }}</span>
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="1"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <registration-completion-form
              :member="internalMember"
              :savingImage="savingImage"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="verifyMember"
                class="mx-2"
                :disabled="!member.FirstName"
              >
                {{
                  $t("components.registration_completion.verify_and_continue")
                }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="2"
            :complete="step2Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step2_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion.step2_subtitle") }}
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="2"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4 step2"
          >
            <!-- <address-registration-form
              :member="internalMember"
              @update-valid="valid.addressValidation = $event"
              @update-member-address="internalMember.Address = $event"
              :saving="saving"
            /> -->
            <v-row no-gutters class="pt-1">
              <ccwipp-address-form
                :hide-save="true"
                :member="member"
                :updateMemberAddress="updateMemberAddress"
                :cancelAddressEdit="cancelAddressEdit"
                :memberSaved="memberSaved"
                :errorMessage="errorMessage"
                @address-edited="
                  isAddressEdited = $event;
                  cancelAddressEdit = false;
                "
                @save-address="saveAddress($event?.Address)"
                @update-valid="valid.addressValidation = $event"
                @clear-error="errorMessage = []"
              />
              <v-overlay :value="saving" absolute class="text-center">
                <div class="mb-4">
                  {{
                    $t("components.registration_completion.validating_address")
                  }}
                </div>
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-row>
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelAddressEditAction">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateMemberAddressAction"
                class="mx-2"
                :disabled="!valid.addressValidation && isAddressEdited"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="3"
            :complete="step3Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step3_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion.step3_subtitle") }}
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="3"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <contacts-registration-form
              :member="internalMember"
              @update-valid="valid.contactsValidation = $event"
              @update-member="internalMember = $event"
              :saving="saving"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateContacts"
                class="mx-2"
                :disabled="!valid.contactsValidation"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="4"
            :complete="step4Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step4_title") }}
            <!-- <small class="mt-2 black--text">
              {{ $t("components.registration_completion.step4_subtitle") }}
            </small> -->
          </v-stepper-step>

          <v-stepper-content
            step="4"
            :complete="step4Completed"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <profile-picture-form
              :member="member"
              @update-valid="valid.contactsValidation = $event"
              @save-image="newAvatarImage = $event"
              :saving="saving"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateProfilePicture"
                class="mx-2"
                :disabled="!valid.contactsValidation"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="5"
            :complete="step === 5"
            class="pl-2 pl-sm-6 pb-0"
          >
            {{ $t("components.registration_completion.step5_title") }}
          </v-stepper-step>

          <v-stepper-content
            step="5"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <div v-if="userProfile?.MemberPlans?.length < 2">
              <v-card-text class="text-subtitle-1 mb-2">
                {{
                  $t("components.member_registration_completed.page_subtitle")
                }}
                <br />
              </v-card-text>
              <v-row no-gutters justify="end">
                <v-btn
                  color="primary"
                  @click="goToDashboard()"
                  class="mx-2"
                  :disabled="!valid.contactsValidation"
                  :loading="saving"
                >
                  {{ $t("globals.close") }}
                </v-btn>
              </v-row>
            </div>
            <!-- Update other plans -->
            <div v-else>
              <v-card-text class="text-subtitle-1">
                {{
                  $t(
                    "components.member_registration_completed.update_all_plans"
                  )
                }}
                <br />
              </v-card-text>
              <v-card-actions class="pa-4 pt-0 pb-1 justify-end">
                <v-btn @click="doNotUpdateOnAllPlans">{{
                  $t("globals.no")
                }}</v-btn>
                <v-btn
                  :loading="updatingPlans"
                  color="primary"
                  class="mr-3"
                  @click="updateMemberPlans"
                  >{{ $t("globals.yes") }}</v-btn
                >
              </v-card-actions>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>

    <!-- Confirm cancellation dialog -->
    <v-dialog max-width="800" v-model="cancelDialog" persistent>
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">mdi-alert-outline</v-icon>
          {{ $t("components.member_registration.cancel_dialog.title") }}
        </v-card-title>
        <v-card-text class="px-5 mb-5 text-subtitle-1">
          <v-row>
            <v-col>{{
              $t("components.member_registration.cancel_dialog.message")
            }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0 justify-end">
          <v-btn @click="cancel">{{ $t("globals.cancel") }}</v-btn>
          <v-btn color="primary" class="mr-3" @click="cancelRegistration">{{
            $t("components.member_registration.cancel_dialog.exit_registration")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="1200" v-model="contactDialog" persistent>
      <v-card class="pa-2">
        <contact-component />
        <v-card-actions class="pt-0 justify-end">
          <v-btn color="primary" @click="contactDialog = false">Go back</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      {{ successMessage }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      :color="vuetifyTheme.Light.Error"
      :timeout="-1"
    >
      {{ customErrorMessage }}
      <span
        class="custom-underline secondary--text"
        @click="contactDialog = true"
        >{{ $t("globals.here") }}</span
      >
      <v-btn dark text @click="errorSnackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <error-handler
      :error="error"
      :link="link"
      :linkText="linkText"
      :time="-1"
    ></error-handler>
  </div>
</template>

<script>
import ErrorHandler from "@/components/ErrorHandler.vue";
import RegistrationCompletionForm from "@/components/RegistrationCompletion/RegistrationCompletionForm.vue";
import ProfilePictureForm from "@/components/RegistrationCompletion/ProfilePictureForm.vue";
// import AddressRegistrationForm from "@/components/RegistrationCompletion/AddressRegistrationForm.vue";
import ContactsRegistrationForm from "@/components/RegistrationCompletion/ContactsRegistrationForm.vue";
import ContactComponent from "@/components/Contact/ContactComponent.vue";
import CcwippAddressForm from "@/components/Ccwipp/CcwippAddressForm.vue";
import { mapGetters, mapActions } from "vuex";
import { tokens } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
export default {
  components: {
    ErrorHandler,
    RegistrationCompletionForm,
    ProfilePictureForm,
    // AddressRegistrationForm,
    ContactsRegistrationForm,
    ContactComponent,
    CcwippAddressForm,
  },
  props: {
    member: Object,
  },
  data() {
    return {
      valid: {
        memberValidation: false,
        addressValidation: false,
        contactsValidation: false,
      },
      savingImage: false,
      telephoneMask: "(###) ### - ####",
      internalMember: undefined,
      cancelDialog: false,
      contactDialog: false,
      newAvatarImage: null,
      error: null,
      saving: false,
      snackbar: false,
      step: 1,
      successMessage: null,
      step1Completed: false,
      step2Completed: false,
      step3Completed: false,
      step4Completed: false,
      updateMemberAddress: false,
      isAddressEdited: false,
      neospinAddressUpdated: false,
      neospinContactInfoUpdated: false,
      neospinPreferenceInfoUpdated: false,
      updatingPlans: false,
      errorMessage: [],
      customErrorMessage: null,
      errorSnackbar: false,
      memberSaved: false,
      memberAddressSaved: false,
      cancelAddressEdit: false,
      timeout: -1,
      link: null,
      linkText: null,
      commsData: null,
      contactsData: null,
      lastSavedAddress: null,
    };
  },
  watch: {
    member: {
      handler(v) {
        this.internalMember = v;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", [
      "planAdministrator",
      "userProfile",
      "enumModels",
      "vuetifyTheme",
    ]),
  },
  mounted() {
    if (this.member) {
      this.internalMember = JSON.parse(JSON.stringify(this.member));
      this.contactsData = JSON.stringify({
        EmailAddress: this.member.EmailAddress,
        SecondaryEmail: this.member.SecondaryEmail,
        MobilePhone: this.unmask(
          this.internalMember.MobilePhone,
          this.telephoneMask
        ),
        HomePhone: this.unmask(
          this.internalMember.HomePhone,
          this.telephoneMask
        ),
        PreferredPhone: this.member.PreferredPhone,
      });
      this.commsData = JSON.stringify({
        Communication: this.member.Communication,
        Language: this.member.Language,
      });
    }
  },
  methods: {
    ...mapActions("auth", ["logout", "setUser", "setIsLoggedIn"]),
    ...mapActions("users", ["updateUser"]),
    ...mapActions("common", ["loadUserProfileData"]),
    ...mapActions("data", [
      "checkValidMember",
      "checkValidEmail",
      "saveMember",
      "saveMemberAddress",
      "saveMemberContactInfo",
      "saveMemberCommunicationInfo",
      "updateMemberInfoOnAllPlans",
    ]),
    ...mapActions("common", ["clearUserProfileData"]),
    async verifyMember() {
      this.step1Completed = true;
      this.step = await this.checkStepsCompletion();
    },

    async updateMemberAddressAction() {
      if (!this.isAddressEdited) {
        this.step2Completed = true;
        this.step = await this.checkStepsCompletion();
        return;
      }
      this.updateMemberAddress = true;
    },

    async saveAddress(address) {
      this.internalMember.Address = address;
      // this.step2Completed = true;
      // this.step = await this.checkStepsCompletion();
      // this.updateMemberAddress = false;
      this.lastSavedAddress = JSON.parse(JSON.stringify(address));
      this.neospinAddressUpdated = false;
      this.saving = true;
      this.successMessage = this.$t(
        "components.registration_completion.update_address.success"
      );
      try {
        await this.saveMemberAddress({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: this.internalMember,
        });
        this.step2Completed = true;
        this.step = await this.checkStepsCompletion();
        this.updateMemberAddress = false;
        this.snackbar = true;
        this.memberAddressSaved = true;
        this.isAddressEdited = false;
        this.neospinAddressUpdated = true;
      } catch (error) {
        this.updateMemberAddress = false;
        console.log(error);
        this.memberAddressSaved = false;

        this.error = {
          customMessage: this.$t(
            "components.address_expansion_panel.error_saving_address"
          ),
        };
        this.step2Completed = false;
        this.step = 2;

        // TODO for testing need to remove
        // this.step2Completed = true;
        // this.step = await this.checkStepsCompletion();
        // this.updateMemberAddress = false;
        // this.snackbar = true;
        // this.memberAddressSaved = true;
        // this.isAddressEdited = false;
        // =================
      } finally {
        this.saving = false;
      }
    },

    async updateContacts() {
      //this.internalMember.Address = null;
      this.lastSavedAddress = this.internalMember.Address;
      this.saving = true;
      this.successMessage = this.$t(
        "components.registration_completion.update_contacts.success"
      );
      try {
        this.internalMember.MobilePhone = this.unmask(
          this.internalMember.MobilePhone,
          this.telephoneMask
        );
        this.internalMember.HomePhone = this.unmask(
          this.internalMember.HomePhone,
          this.telephoneMask
        );

        const newContactsData = JSON.stringify({
          EmailAddress: this.internalMember.EmailAddress,
          SecondaryEmail: this.internalMember.SecondaryEmail,
          MobilePhone: this.internalMember.MobilePhone,
          HomePhone: this.internalMember.HomePhone,
          PreferredPhone:
            this.internalMember.PreferredPhone === "Home"
              ? this.internalMember.HomePhone
              : this.internalMember.MobilePhone,
        });
        this.internalMember.PreferredPhone =
          this.internalMember.PreferredPhone === "Home"
            ? this.internalMember.HomePhone
            : this.internalMember.MobilePhone;
        const newCommsData = JSON.stringify({
          Communication: this.internalMember.Communication,
          Language: this.internalMember.Language,
        });

        if (this.contactsData !== newContactsData) {
          console.log("contacts changed");
          console.log("contacts", this.contactsData, newContactsData);
          await this.saveMemberContactInfo({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
            payload: this.internalMember,
          });
        }

        if (this.commsData !== newCommsData) {
          console.log("communications changed");
          console.log("comms", this.commsData, newCommsData);
          await this.saveMemberCommunicationInfo({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
            payload: this.internalMember,
          });
        }

        this.step3Completed = true;
        this.step = await this.checkStepsCompletion();
        this.snackbar = true;
        this.neospinContactInfoUpdated = true;
        this.neospinPreferenceInfoUpdated = true;
      } catch (error) {
        this.internalMember.MobilePhone = this.mask(
          this.internalMember.MobilePhone,
          this.telephoneMask
        );
        this.internalMember.HomePhone = this.mask(
          this.internalMember.HomePhone,
          this.telephoneMask
        );
        console.log(error);
        this.error = {
          customMessage: this.$t(
            "components.registration_completion.update_contacts.error"
          ),
        };
        this.step3Completed = false;
        this.step = 3;

        // TODO for testing need to remove
        // this.step3Completed = true;
        // this.step = await this.checkStepsCompletion();
        // this.updateMemberAddress = false;
        // this.snackbar = true;
        // this.memberAddressSaved = true;
        // this.isAddressEdited = false;
        // =================
      } finally {
        this.saving = false;
      }
    },

    async updateMemberPlans() {
      this.updatingPlans = true;
      this.snackbar = false;
      this.errorSnackbar = false;
      this.successMessage = this.$t(
        "components.registration_completion.update_plans.success"
      );
      let address = this.lastSavedAddress;
      if (!address?.FullStreetName) {
        const streetType = this.isPensionsPortal
          ? address.StreetType
          : address.StreetType?.value;
        this.lastSavedAddress.FullStreetName =
          address.StreetName +
          (address.StreetType ? " " + streetType : "") +
          (address.StreetDirection ? " " + address.StreetDirection : "");
      }
      if (address.Category === "Civic") {
        const province = this.enumModels.Province.find(
          (p) => p.code === address.Province
        );
        this.lastSavedAddress.ProvinceName = province?.textEn;
        this.lastSavedAddress.ProvinceId = province?.id;
        this.lastSavedAddress.Country = this.$helpers.capitalize(
          address.Country
        );
      }
      if (address.Category === "Non-Civic US") {
        this.lastSavedAddress.OtherCountry = "United States";
        this.lastSavedAddress.OtherProvince = address.State;
        this.lastSavedAddress.OtherPostalCode =
          address.ZipCode + (address.Zip4Code ? "-" + address.Zip4Code : "");
      }
      if (address.Category === "Non-Civic International") {
        this.lastSavedAddress.OtherCountry = address.Country;
        this.lastSavedAddress.OtherProvince = address.Province;
        this.lastSavedAddress.OtherPostalCode = address.PostalCode;
      }
      console.log("updating addrress", this.lastSavedAddress);
      try {
        await this.updateMemberInfoOnAllPlans({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            memberProfile: {
              Address: this.lastSavedAddress,
              BankAccount: this.member.BankAccount,
              EmailAddress: this.member.EmailAddress,
              SecondaryEmail: this.member.SecondaryEmail,
              HomePhone: this.member.HomePhone,
              MobilePhone: this.member.MobilePhone,
              Communication: this.member.Communication,
              Language: this.member.Language,
              PreferredPhone: this.phoneType || this.member.PreferredPhone,
            },
            memberPlans: this.member.MemberPlans,
            updateFlags: {
              neospinAddressUpdated: this.neospinAddressUpdated,
              neospinContactInfoUpdated: this.neospinContactInfoUpdated,
              neospinPreferenceInfoUpdated: this.neospinPreferenceInfoUpdated,
              bankAccountUpdated: this.bankAccountUpdated,
              drawbridgeContactInfoUpdated: this.drawbridgeContactInfoUpdated,
            },
          },
        });
        this.snackbar = true;
        this.lastSavedAddress = null;
        await this.loadUserProfileData({
          planId: this.user.plan,
          userId: this.user.sub,
        });
        this.snackbar = true;
        this.link = null;
        this.linkText = null;
        this.goToDashboard();
      } catch (error) {
        console.log(error);
        this.customErrorMessage = this.$t(
          "components.registration_completion.update_plans.error"
        );
        this.step = 5;
        this.errorSnackbar = true;
        await this.updateUser({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            ...this.user,
            isRegistrationCompleted: false,
          },
        });
      } finally {
        this.updatingPlans = false;
      }
    },

    async doNotUpdateOnAllPlans() {
      await this.updateUser({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
        payload: {
          ...this.user,
          isRegistrationCompleted: true,
        },
      });
      this.goToDashboard();
    },

    contactUs() {
      this.contactDialog = true;
    },

    cancelAddressEditAction() {
      if (this.isAddressEdited) {
        this.cancelAddressEdit = true;
      } else {
        this.cancelDialog = true;
      }
    },

    cancel() {
      this.cancelDialog = false;
    },

    async updateProfilePicture() {
      this.step4Completed = true;
      this.step = await this.checkStepsCompletion();
    },

    async checkStepsCompletion() {
      if (!this.step1Completed) {
        if (this.step != 1) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 1;
      }
      if (!this.step2Completed) {
        if (this.step != 1) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 2;
      }
      if (!this.step3Completed) {
        if (this.step != 2) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 3;
      }
      if (!this.step4Completed) {
        return 4;
      }
      const result = await this.saveUser(true);

      return result;
    },

    cancelRegistration() {
      this.clearUserProfileData();
      this.logout();
      this.setUser(undefined);
      this.setIsLoggedIn(false);
      this.$router.push({ name: "login" });
    },

    unmask(v, mask) {
      if (v) {
        return masker(v, mask, false, tokens);
      }
      return v;
    },

    mask(v, mask) {
      if (v) {
        return masker(v, mask, true, tokens);
      }
      return v;
    },

    goToDashboard() {
      this.$emit("close-dialog");
      this.$router.push("/");
    },

    async saveUser(isRegistrationCompleted) {
      this.saving = true;
      this.successMessage = this.$t(
        "components.registration_completion.save_member.success"
      );
      try {
        // this.internalMember.MobilePhone = this.unmask(
        //   this.internalMember.MobilePhone,
        //   this.telephoneMask
        // );
        // this.internalMember.HomePhone = this.unmask(
        //   this.internalMember.HomePhone,
        //   this.telephoneMask
        // );
        // this.memberSaved = true;
        // this.errorMessage = [];
        // await this.saveMember({
        //   planIdentityId: this.user.plan,
        //   memberIdentityId: this.user.sub,
        //   payload: this.internalMember,
        // });

        await this.updateUser({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            ...this.user,
            image: this.newAvatarImage,
            isRegistrationCompleted: isRegistrationCompleted,
          },
        });
        this.snackbar = true;
        await this.loadUserProfileData({
          planId: this.user.plan,
          userId: this.user.sub,
        });
        this.memberSaved = true;
        return 5;
      } catch (error) {
        console.log(error);
        // this.error = error;
        this.memberSaved = false;
        this.error = {
          customMessage: this.$t(
            "components.registration_completion.error.unable_to_complete"
          ),
        };
        // =========== REMOVE after backend call is working =====
        // await this.updateUser({
        //   planIdentityId: this.user.plan,
        //   memberIdentityId: this.user.sub,
        //   payload: {
        //     ...this.user,
        //     image: this.newAvatarImage,
        //     isRegistrationCompleted: isRegistrationCompleted,
        //   },
        // });
        // this.snackbar = true;
        // this.loadUserProfileData({
        //   planId: this.user.plan,
        //   userId: this.user.sub,
        // });
        //=================================================
        return this.step;
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>
.step2 {
  position: relative;
}
</style>
